import {Injectable} from '@angular/core';
import {CacheDatabaseContext, IndexedDbAdapter} from '@juulsgaard/store-service';

@Injectable()
export class UserCacheContext extends CacheDatabaseContext {

  constructor(adapter: IndexedDbAdapter) {
    super(adapter, 'user');
  }
}
